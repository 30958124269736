<template>
	<div>
		<div class="py-25 mx-25">
			<div class="section-content py-10">
				<div class="container-fluid">
					<aq-list-navigation-control
						:list="tasks"
						:current-item="currentItem"
						@current-changed="onSelect"
					>
						<template slot-scope="slotProps">
							<div
								class="py-5 position-relative"
								v-if="!filtersOpened && filterTerms.length"
							>
								<div class="d-flex flex-wrap">
									<aq-search-term
										v-for="term of filterTerms"
										:header="term.label"
										:key="term.key"
										:value="term.value"
										header-class="text-primary"
										class="px-10 mr-5 mb-5 py-6 search-term__rounded align-items-center"
										@remove="$emit('delete-claim-filter', term)"
										:data-qa="`dashboardTable_searchTerm_${term.key}`"
									/>
								</div>
							</div>
							<el-table
								class="dashboard-table mb-10"
								:class="{ 'opacity-03': filtersOpened }"
								:data="slotProps.list"
								empty-text="There is no data to display"
								@row-click="onSelect"
								:row-class-name="getRowClassName"
								:cell-class-name="getCellClassName"
							>
								<el-table-column
									width="55"
									column-key="ClaimSource"
									v-if="tasks.some(task => task.prioritizedWorkQueue || $options.claimSourceLabelMap[task.claimSource])"
								>
									<template slot-scope="scope">
										<div class="claim-source-container">
											<aq-bookmark
												class="claim-source"
												v-if="$options.claimSourceLabelMap[scope.row.claimSource]"
												:label="$options.claimSourceLabelMap[scope.row.claimSource]"
												:tooltip="`Source: ${$options.claimSourceDisplayMap[scope.row.claimSource]}`"
											/>
										</div>
										<div
											v-if="scope.row.prioritizedWorkQueue"
											class="auto-assigned-icon"
										>
											<i class="aqv-bell" />
										</div>
									</template>
								</el-table-column>
								<el-table-column
									width="55"
									column-key="FastTrackReviewed"
									v-if="tasks.some(task => task.isFastTrackReviewed)"
								>
									<template slot-scope="scope">
										<icon-cell
											v-if="scope.row.isFastTrackReviewed"
											icon-class="aqv-fast-track fs-25"
											tooltip="Fast track reviewed"
										/>
									</template>
								</el-table-column>
								<el-table-column
									v-if="tasks.some(task => task.missingInfoCompleted)"
									width="55"
									column-key="missingInfoCompleted"
								>
									<template slot-scope="scope">
										<icon-cell
											v-if="scope.row.missingInfoCompleted"
											icon-class="aqv-missing-info-complete fs-25"
											tooltip="Missing Info Complete"
										/>
									</template>
								</el-table-column>
								<el-table-column
									label="Brand"
									width="55"
								>
									<template slot-scope="scope">
										<aq-image-avatar
											class="small mr-4 cursor-inherit"
											:file-uri="getBrandLogo(scope.row.brandId)"
											:alt-text="scope.row.brandName"
										/>
									</template>
								</el-table-column>
								<el-table-column
									label="Claim ID"
									width="85"
								>
									<template slot-scope="scope">
										<div>{{ scope.row.claimId | claimIdFormat }}</div>
									</template>
								</el-table-column>
								<el-table-column
									label="Queue"
									width="120"
								>
									<template slot-scope="scope">
										<truncated-text-cell
											:value="scope.row.workQueueName || '-'"
										/>
									</template>
								</el-table-column>
								<el-table-column
									label="Customer"
									width="85"
								>
									<template slot-scope="scope">
										<router-link
											:to="{ name: 'customer', params: { customerId: scope.row.customerId } }"
											class="d-inline-block"
										>
											<aq-icon-avatar
												class="small cursor-pointer"
												:value="[
													scope.row.customerFirstName,
													scope.row.customerLastName,
												]"
											/>
										</router-link>
									</template>
								</el-table-column>
								<el-table-column
									label="Pet"
									width="85"
								>
									<template slot-scope="scope">
										<router-link
											:to="{ name: 'pet', params: { customerId: scope.row.customerId, petId: scope.row.petId } }"
											class="d-inline-block"
										>
											<aq-pet-avatar
												class="small cursor-inherit"
												:pet="{
													name: scope.row.petName,
													type: scope.row.petType,
													gender: scope.row.petGender,
													dateOfDeath: scope.row.petDateOfDeath,
												}"
											/>
										</router-link>
									</template>
								</el-table-column>
								<el-table-column label="Ailment">
									<template slot-scope="scope">
										<truncated-text-cell
											:value="scope.row.ailments || 'No Ailment'"
										/>
									</template>
								</el-table-column>
								<el-table-column
									label="Amount"
									width="70"
								>
									<template slot-scope="scope">
										<div>
											{{ scope.row.claimAmount | currency(scope.row.brandLocale) }}
										</div>
									</template>
								</el-table-column>
								<el-table-column
									width="70"
								>
									<template slot-scope="scope">
										<div class="d-flex justify-content-center">
											<component
												v-if="scope.row.payeeTypes.length"
												:data-qa="`dashboardTable_paymentIcon_${getPayeeTypeDesc(scope.row.payeeTypes)}`"
												:is="getPaymentIcon(scope.row.payeeTypes)"
												class="icon-small ml-10 cursor-pointer"
											/>
										</div>
									</template>
								</el-table-column>
								<el-table-column
									label="Claim Date"
									width="150"
									prop="createdDate"
								>
									<div slot-scope="scope">
										<div>{{ scope.row.createdDate | shortDateTime }}</div>
									</div>
								</el-table-column>
								<el-table-column
									label="Time Open"
									width="110"
								>
									<template slot-scope="scope">
										<div>{{ openedDateElapsedTime(scope.row.reassessmentDateTime ?? scope.row.createdDate) }}</div>
									</template>
								</el-table-column>
								<el-table-column label="Task Type">
									<div slot-scope="scope">
										<div>{{ scope.row.taskTypeTitle }}</div>
									</div>
								</el-table-column>
								<el-table-column
									label="Item"
									width="130"
								>
									<template slot-scope="scope">
										<template v-if="scope.row.workQueueType === workQueueType.PaymentApproval">
											<div
												class="link text-primary"
												@click="$emit('select-payees', scope.row)"
											>
												<strong>{{ scope.row.payeeTypes.length }}</strong>
												{{ 'Payee' | pluralize(scope.row.payeeTypes.length) }}
											</div>
										</template>
										<div
											class="link text-danger-20"
											v-else-if="scope.row.workQueueType === workQueueType.FraudCheck"
											@click="$emit('select-flags', scope.row.fraudCheckReasons)"
										>
											<strong>{{ scope.row.fraudCheckReasons.length }}</strong>
											{{ 'Flag' | pluralize(scope.row.fraudCheckReasons.length) }}
										</div>
										<template v-else-if="scope.row.workQueueType === workQueueType.RejectionApproval">
											<truncated-text-cell
												v-if="scope.row.rejectionReason || scope.row.rejectionStatus"
												:value="scope.row.rejectionReason || scope.row.rejectionStatus"
												class="text-danger-20"
											/>
											<div
												v-else
												class="text-gray"
											>
												No Reason
											</div>
										</template>
										<template v-else-if="scope.row.workQueueType === workQueueType.Chasing">
											<div
												v-if="scope.row.missingInformation.length > 0"
												class="link text-primary"
												@click="$emit('select-missing', scope.row.missingInformation)"
											>
												<strong>{{ scope.row.missingInformation.length }}</strong>
												{{ 'Item' | pluralize(scope.row.missingInformation.length) }}
											</div>
										</template>
										<template v-else-if="scope.row.workQueueType === workQueueType.DraftClaims && isPreAuth(scope.row.formType)">
											<div>{{ getPreAuthLabel() }}</div>
										</template>
										<template v-else-if="[workQueueType.ReassessmentPending, workQueueType.DraftClaims].includes(scope.row.workQueueType)">
											<div>-</div>
										</template>
									</template>
								</el-table-column>
								<el-table-column
									label="Latest"
									width="400"
								>
									<template slot-scope="scope">
										<aq-task-details-popover
											v-if="scope.row.latestInteraction || scope.row.latestDashboardNote"
											:interaction="scope.row.latestInteraction"
											:note="scope.row.latestDashboardNote"
										/>
										<div v-else>
											-
										</div>
									</template>
								</el-table-column>
								<el-table-column width="115">
									<template slot-scope="scope">
										<aq-dropdown
											:options="getDropdownOptions(scope.row)"
											label="View"
											btn-class="small btn-row"
											@select="$emit('row-action', $event, scope.row)"
											@main-action="onMainAction(scope.row)"
											:split-toggle="true"
											:is-shortkey-context="scope.row === currentItem"
											:boundaries-selector="tableBoundariesQuery"
										/>
									</template>
								</el-table-column>
							</el-table>
						</template>
					</aq-list-navigation-control>

					<div
						class="filters-container"
						@click="filtersOpened = true"
					>
						<BannerWrap class="position-absolute" />
						<div class="d-flex position-relative mt-15 align-items-center justify-content-center">
							<i class="fa-solid fa-filter fs-20 ml-3" />
							<span class="ml-5">Filters</span>
						</div>

						<aq-table-popover
							v-if="filtersOpened"
							class="filters-popover"
							:open="filtersOpened"
							@hide="filtersOpened = false"
							:show-arrow="false"
						>
							<template>
								<div class="claims-grid-filter">
									<div class="filter-header d-flex flex-row align-itens-center py-10 px-15">
										<i class="fa-solid fa-filter fs-24" />
										<div class="ml-10 d-flex flex-column">
											<label class="font-weight-bold fs-15">Filters & Sorting</label>
											<label class="fs-11 mt-2">Filter & sort all available tasks</label>
										</div>
									</div>
									<claims-filter
										:class="{ 'open' : filtersOpened }"
										data-qa="petClaims_modal_filter"
										:filters="filters"
										:sorting="sorting"
										@apply-filters="$emit('apply-claim-filters', $event); filtersOpened = false"
										@clear-filters="$emit('clear-claim-filters'); filtersOpened = false"
									/>
								</div>
							</template>
						</aq-table-popover>
					</div>
				</div>
			</div>
		</div>
		<div class="section-footer pl-45 pr-20 d-flex justify-content-end">
			<aq-page-control
				v-if="paging.rowCount"
				:total="paging.rowCount"
				:page-size="paging.pageSize"
				@page-changed="onPageChanged"
			/>
		</div>
	</div>
</template>

<script>
import eventBus from '@commonServices/eventBus';
import rowHighlightMixin from '@mixins/rowHighlightMixin';
import { WorkQueueType,	getDropdownOptionsForPot, dropdownActions } from '@commonServices/models/WorkQueueType';
import FileService from '@commonServices/fileService';
import { mapActions } from 'vuex';
import AqTaskDetailsPopover from '@commonView/AqTaskDetailsPopover';
import { ClaimFormTypeDisplayMap, ClaimFormType } from '@commonServices/models/ClaimFormType';
import ClaimsFilter from '@commonView/Shared/ClaimsFilter';
import AqTablePopover from '@/clients/clientCommon/components/widgets/AqTablePopover';
import { BannerWrap, PolicyHolder, Vet, MultiPayments } from '@assets/icons';
import { claimSourceLabelMap, claimSourceDisplayMap } from '@clientCommon/services/models/ClaimSource';
import { timeBetweenFormatted } from '@commonServices/utils/dateUtils';
import { PayeeType } from '@commonServices/utils/payeeOptions';
import FilterComponentTypeEnum from '@commonServices/models/FilterComponentTypeEnum';
import { ClaimFilterDisplayMap } from '@commonServices/models/ClaimFilterEnum';

const intervalWorker = new Worker(new URL('@workers/intervalWorker.js', import.meta.url));

export default {
	name: 'DashboardTable',
	components: {
		AqTaskDetailsPopover,
		AqTablePopover,
		ClaimsFilter,
		BannerWrap,
	},
	claimSourceLabelMap,
	claimSourceDisplayMap,
	mixins: [rowHighlightMixin],
	props: {
		tasks: {
			type: Array,
			required: true,
		},
		paging: {
			type: Object,
			required: true,
		},
		getTaskAvailable: {
			type: Boolean,
			required: true,
		},
		filters: {
			type: Array,
			required: true,
		},
		sorting: {
			type: Object,
			required: true,
		},
	},
	emits: ['toggle-task-view'],
	data () {
		return {
			workQueueType: WorkQueueType,
			tableBoundariesQuery: '.dashboard-table',
			filtersOpened: false,
			currentTime: new Date(),
		};
	},
	created () {
		const self = this;
		intervalWorker.onmessage = (e) => {
			const { id, command } = e.data;
			if (id === self._uid && command === 'timer-tick') {
				self.currentTime = new Date();
			}
		};
		intervalWorker.postMessage({
			id: self._uid,
			command: 'timer-start',
			interval: 1,
		});
	},
	mounted () {
		this.currentItem = this.tasks[0];
	},
	computed: {
		filterTerms () {
			return this.filters.filter(f => f.value)
				.map(f => {
					const value = f.type === FilterComponentTypeEnum.Checkbox ? f.value ? 'Yes' : 'No' : f.value.description;
					return {
						key: f.key, label: ClaimFilterDisplayMap[f.key], type: f.type, value,
					};
				});
		},
	},
	methods: {
		...mapActions(['resetInteractionContext']),
		openedDateElapsedTime (time) {
			return timeBetweenFormatted(time, this.currentTime);
		},
		getBrandLogo (brandId) {
			return FileService.getBrandLogo(brandId);
		},
		getDropdownOptions (data) {
			return getDropdownOptionsForPot(data.workQueueType, this.$can, data);
		},
		onMainAction (data) {
			this.$emit('row-action', dropdownActions.view.value, data);
		},
		onPageChanged (pageNumber) {
			this.resetSelection();
			const index = (pageNumber - 1) * this.paging.pageSize;
			this.$emit('page-changed', index);
		},
		getIndex ({ $index }) {
			return this.paging.rangeStart + $index;
		},
		getRowClassName ({ row }) {
			const claimId = this.currentItem?.claimId;
			const isSelectedItem = claimId === row.claimId;
			const highlightAutoAssigned = row.prioritizedWorkQueue ? 'auto-assigned' : '';
			const cursor = isSelectedItem ? 'cursor-default' : 'cursor-pointer';
			if (isSelectedItem) {
				return `border-highlighted ${highlightAutoAssigned} ${cursor} shadow`;
			}
			const disabled = !isSelectedItem && claimId;
			if (disabled) {
				return `${highlightAutoAssigned} ${cursor} opacity-66`;
			}
			return `${highlightAutoAssigned} ${cursor}`;
		},
		onSelect (newCurrentItem) {
			this.currentItem = newCurrentItem;
			this.$emit('select-task-item', this.currentItem);
		},
		getCellClassName ({ row, column }) {
			if ((column.columnKey === 'FastTrackReviewed' && row.isFastTrackReviewed)
			|| (column.columnKey === 'missingInfoCompleted' && row.missingInfoCompleted)) {
				return 'table__icon-cell';
			}
		},
		resetSelection () {
			this.currentItem = null;
		},
		isPreAuth (formType) {
			return formType === ClaimFormTypeDisplayMap[ClaimFormType.PreAuthorisation];
		},
		getPreAuthLabel () {
			return ClaimFormTypeDisplayMap[ClaimFormType.PreAuthorisation];
		},
		getPaymentIcon (payeeTypes) {
			if (payeeTypes.length > 1) {
				return MultiPayments;
			}
			return payeeTypes[0] === PayeeType.PolicyHolder ? PolicyHolder : Vet;
		},
		getPayeeTypeDesc (payeeTypes) {
			if (payeeTypes.length > 1) {
				return 'MultiPayments';
			}
			return payeeTypes[0] === PayeeType.PolicyHolder ? 'PolicyHolder' : 'Vet';
		},
	},
	watch: {
		tasks: {
			deep: true,
			handler () {
				eventBus.$emit('hide-panels');
				this.resetSelection();
				this.resetInteractionContext();
			},
		},
	},
	beforeDestroy () {
		intervalWorker.postMessage({
			id: this._uid,
			command: 'timer-stop',
		});
	},
};
</script>

<style lang="scss" scoped>
::v-deep .user-name {
  font-size: 26px;
  letter-spacing: -0.06em;
  font-weight: bold;
}

.claim-source-container {
  width: 50px;

  .claim-source {
    left: 0;
    top: 20px;
  }
}

.auto-assigned-icon {
  position: absolute;
  top: -1px;
  left: -1px;
  border-style: solid;
  border-width: 30px 30px 0 0;
  border-color: $active-color transparent transparent;
  color: white;
  width: 0;
  height: 0;
  font-size: 11px;

  i {
    position: relative;
    top: -32px;
    left: 3px;
  }
}

.filters-container {
  color: $body-color;
  width: 86px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: -10px;

  .filters-popover {
    position: relative;
    top: -53px;
    right: 60px;
  }
}

.claims-grid-filter {
  background-color: var(--contentBg);
  color: var(--bodyColour) !important;
  width: 300px;
  box-shadow: 15px 15px 25px 1px rgba(0, 0, 0, 0.6);

  .filter-header {
    background-color: var(--cardIconColour);
    color: var(--sectionTextColour);
  }
}

::v-deep tr {
  &.auto-assigned {
    td {
      border-top: 3px solid $warning;
      border-bottom: 3px solid $warning;
    }

    td:first-child {
      border-left: 3px solid $warning;
    }

    td:last-child {
      border-right: 3px solid $warning;
    }
  }
}

::v-deep th .cell,
::v-deep td .cell {
  padding-left: 5px;
  padding-right: 5px;
}

::v-deep td {
  &:nth-last-child(2) .cell {
    padding-right: 5px;
  }
}

.aqv-bell {
  display: inline-block;
  transform: rotate(15deg);
}

.icon-small {
  fill: var(--cardIconSVG);
  transform: scale(0.7);
}
</style>
